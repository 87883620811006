import React, { useState, useEffect } from "react"
import queryString from 'query-string'

export const AcodeContext = React.createContext([])

const AcodeProvider = ({ children }) => {
    const [acode, setAcode] = useState(null)
    const [acodeSetTime, setAcodeSetTime] = useState(null)

    // pull out the acode from a url string like: "?acode=23ewsdf" (and set it to the provider)
    const parseAcode = (search) => {
        console.log("[#4s89f] URL search string: " + search)
        
        const newAcode = queryString.parse(search).acode
        if (newAcode) {
            localStorage.setItem('acode', newAcode)
        
            setAcode(newAcode)
            console.log('[#4s89f] New acode set: ' + newAcode)

            const currentdate = new Date();
            localStorage.setItem('acodeSetTime', currentdate)

            setAcodeSetTime(currentdate)
        }
    }

    useEffect(() => {
        const lastAcode = localStorage.getItem('acode')
        setAcode(lastAcode)

        const lastAcodeSetTime = localStorage.getItem('acodeSetTime')
        setAcodeSetTime(lastAcodeSetTime)

        console.log('[#4s89f] in useEffect (for AcodeProvider): ' + lastAcode + ' settime: ' + lastAcodeSetTime)
        return () => {
        };

    }, []);

    return (
        <AcodeContext.Provider
            value={{
                acode,
                setAcode,
                parseAcode,
                acodeSetTime,
                setAcodeSetTime
            }}
        >
            {children}
        </AcodeContext.Provider>
    )
}

export default AcodeProvider