import React, { useState, useEffect } from "react";
import axios from "axios";

export const CurrentUserContext = React.createContext({
    currentUser: null,
    setCurrentUser: () => {},
    setLoggedInUser: () => {}
});

const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [logoutToken, setLogoutToken] = useState('');
  
  console.log('[CurrentUserProvider] currentUser: ', currentUser)
  const baseURL = 'https://dev-tuckel.pantheonsite.io/'
  const stdHeaders = {
    headers: {
        "Content-Type": 'application/vnd.api+json'
    },
    mode: 'no-cors',
    withCredentials: true,
    credentials: 'same-origin'
  }

  useEffect(() => {
    hydrateFromMemory()

    console.log('[#CurrentUserProvider] in useEffect')
    return () => {
    };

  }, []);

  const hydrateFromMemory = () => {
    setCurrentUser(JSON.parse(localStorage.getItem('currentUser')))
    setCsrfToken(localStorage.getItem('csrfToken'))
    setLogoutToken(localStorage.getItem('logoutToken'))
  }

  // attempt login
  // successFunc and failureFunc provide a function (to complete work) that abstracts away the drupal operations from the main app.
  // (Postman example responses at: '5.1 Login')
  const login = ({ identifier, password, successFunc, failureFunc, rememberMeOn = false, _remember_me = false }) => {
    // todo: throw new Error if user is already logged in

    const loginURL = baseURL + 'user/login?_format=json'
    
    const sendAsJson = true
    var contentType = 'error- configure me!'
    var data
    if (sendAsJson) {
        contentType = "application/json"
        data = {
            name: identifier,
            pass: password
        }
    } else {
        // contentType = ???
        data = new FormData();
        data.append('name', identifier);
        data.append('pass', password);
        if(rememberMeOn) {
            data.append('_remember_me', _remember_me);
        }
    }
    
    console.log('calling (#sf3F2): ' + loginURL)
    
    const promise = axios.post(loginURL, data, stdHeaders)

    promise.then((response) => {
      console.log('[LoginForm] 200 response: ', response)
      successFunc(response)

      const data = response.data
      setLoggedInUser(data)
    }).catch((e) => {
      console.log('[LoginForm] fail: ', e)
      const data = e.response.data

      var msg = data.message
      if (msg = 'This route can only be accessed by anonymous users.') {
        // strangely, this is how drupal responds when you are already logged in.
        msg = 'You are already logged in.'
      }

      failureFunc(e, msg)
    });

    return promise
  }

  // Find if a user is logged in, or update the app's user contexts if not
  const setLoggedInUser = (data) => {
    // alert('firstName')
    // alert ('This need to be configured.')

    console.log('[setLoggedInUser] data: ', data)

    if (data.current_user) {

      setUser(data.current_user, data.csrf_token, data.logout_token)
    } else {
      purgeCurrentUser()
    }
  }

  const setUser = (tempCurrentUser, tempCsrfToken, tempLogoutToken) => {
    localStorage.setItem('currentUser', JSON.stringify(tempCurrentUser))
    localStorage.setItem('csrfToken', tempCsrfToken)
    localStorage.setItem('logoutToken', tempLogoutToken)

    setCurrentUser(tempCurrentUser)
    setCsrfToken(tempCsrfToken)
    setLogoutToken(tempLogoutToken)
  }

  // remove logged in user from localStorage and from contexts
  const purgeCurrentUser = () => {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('csrfToken')
    localStorage.removeItem('logoutToken')

    setCurrentUser(null)
    setCsrfToken(null)
    setLogoutToken(null)
  }

  const logout = (onCompletion) => {
    console.log('[logout] onCompletion: ', onCompletion)
    console.log('[logout] attempting logout. User: ', currentUser)
    // more info on Drupal logout: https://drupal.stackexchange.com/questions/225095/how-do-i-log-out-with-rest

    // alert('attempting logout')
    // return null
    // const logoutToken = currentUser.logout_token
    // const csrf_token = currentUser.csrf_token

    // const CSRFToken = axios.get(
    //   'https://dev-tuckel.pantheonsite.io/session/token',
    // ).then((response) => {
    //   console.log('CSRF token (#zdfas): ', response.data)
    //   sendLogoutRequest (response.data)
    // })
    
    // (Postman example responses at: '6.1 Logout')
    const sendLogoutRequest = () => {
      const headers = {
        "X-CSRF-Token": csrfToken,
        "Content-Type": 'application/json'
      }

      const url = 'https://dev-tuckel.pantheonsite.io/user/logout?_format=json&token=' + logoutToken
      const promise = axios.post(url, null, stdHeaders).then((response) => {
        // alert('successful logout')
        
        console.log('[logout] successful. Promise result: ', response)
      }).catch((e) => {
        // alert ("there was an errror in your request. \n", e.response)
        console.log('[logout] failed. Promise response: ', e.response)

        // purge user EVEN if not logged in (may have been logged out somewhere else)
      })

      if (onCompletion) {
        onCompletion()
      }

      purgeCurrentUser()
  
      console.log('[logout] axios headers: ', headers)
    }
    
    sendLogoutRequest ()
    
  }

  // work from here, why isn't this being called? zzz
  const isLoggedIn = () => {
    console.log('isLoggedIn() currentUser zzxc3: ', currentUser)
    if (currentUser) { 
      return true
    }
    return false
  }

  return (
    <CurrentUserContext.Provider value={{
        currentUser,
        setCurrentUser,
        logoutToken,
        csrfToken,
        setLoggedInUser,
        isLoggedIn,
        logout,
        login
    }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;