import React from "react"
import ModeProvider from "context/ModeProvider"
import AcodeProvider from "context/AcodeProvider"
import CurrentUserProvider from "context/CurrentUserProvider"

export const wrapRootElement = ({ element }) => (
  <ModeProvider>
    <AcodeProvider>
      <CurrentUserProvider>
        {element}
      </CurrentUserProvider>
    </AcodeProvider>
  </ModeProvider>
)