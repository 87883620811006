// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-create-a-new-account-js": () => import("./../../../src/pages/CreateANewAccount.js" /* webpackChunkName: "component---src-pages-create-a-new-account-js" */),
  "component---src-pages-forgot-your-password-js": () => import("./../../../src/pages/ForgotYourPassword.js" /* webpackChunkName: "component---src-pages-forgot-your-password-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-bucktlist-js": () => import("./../../../src/pages/MyBucktlist.js" /* webpackChunkName: "component---src-pages-my-bucktlist-js" */),
  "component---src-pages-testing-js": () => import("./../../../src/pages/testing.js" /* webpackChunkName: "component---src-pages-testing-js" */),
  "component---src-templates-activity-plan-js": () => import("./../../../src/templates/ActivityPlan.js" /* webpackChunkName: "component---src-templates-activity-plan-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/BasicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-itineraries-js": () => import("./../../../src/templates/Itineraries.js" /* webpackChunkName: "component---src-templates-itineraries-js" */),
  "component---src-templates-itinerary-activities-js": () => import("./../../../src/templates/ItineraryActivities.js" /* webpackChunkName: "component---src-templates-itinerary-activities-js" */),
  "component---src-templates-itinerary-highlights-js": () => import("./../../../src/templates/ItineraryHighlights.js" /* webpackChunkName: "component---src-templates-itinerary-highlights-js" */),
  "component---src-templates-itinerary-photographs-js": () => import("./../../../src/templates/ItineraryPhotographs.js" /* webpackChunkName: "component---src-templates-itinerary-photographs-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/Location.js" /* webpackChunkName: "component---src-templates-location-js" */)
}

